import React from 'react';
import { createRoot } from 'react-dom/client';
import { Web3ReactProvider } from '@web3-react/core';
import { metaMask, metaMaskHooks, walletConnectV2, walletConnectV2Hooks } from './configs/evmConnecters';
import 'normalize.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './api/axios.config';
import 'react-loading-skeleton/dist/skeleton.css';
import { VenlyWalletProvider } from './providers/venlyWalletProvider';

const connectors: any = [
  [walletConnectV2, walletConnectV2Hooks],
  [metaMask, metaMaskHooks],
];

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Web3ReactProvider connectors={connectors}>
    <VenlyWalletProvider>
      <App />
    </VenlyWalletProvider>
  </Web3ReactProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
