import React, { useEffect } from 'react';
import styled from 'styled-components';
import Modal from './Modal';
import ConnectButton from './ConnectButton';
import color from '../styles/color';
import { useVenlyWalletContext } from '../providers/venlyWalletProvider';

const StyledContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  filter: drop-shadow(-1px 0px 0px ${color.white})
          drop-shadow(1px 1px 0px ${color.white})
          drop-shadow(0px 1px 0px ${color.white})
          drop-shadow(1px -1px 0px ${color.white});
`;

const StyledTitle = styled.div`
  font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  color: ${color.white};
  text-align: center;
  font-weight: bold
`;

type Props = {
  hasBackDrop: boolean;
  opened: boolean;
  onClose: () => void;
}

const BindingModal = ({
  hasBackDrop,
  opened,
  onClose,
}: Props) => {
  const {
    venlyWallet: selectVenlyWallet,
    venlyWallets,
    setVenlyWallet,
  } = useVenlyWalletContext();

  const convertAddressToSimple = (address: string) => {
    const prefixStr = address.substring(0, 6);
    const suffixStr = address.substring(address.length - 4);
    return `${prefixStr}...${suffixStr}`;
  };

  useEffect(() => {
    if (selectVenlyWallet) onClose();
  }, [selectVenlyWallet]);

  return (
    <Modal
      hasBackDrop={hasBackDrop}
      opened={opened}
      onClose={onClose}
      backgroundColor={color.purple}
      closeColor={color.white}
      width="30%"
    >
      <StyledTitle>Choose an account</StyledTitle>
      <StyledContainer>
        {venlyWallets.map((wallet) => (
          <ConnectButton
            name={convertAddressToSimple(wallet.address)}
            subName="Polygon"
            onClick={() => {
              setVenlyWallet(wallet);
            }}
          />
        ))}
      </StyledContainer>
    </Modal>
  );
};

export default BindingModal;
