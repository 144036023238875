import React from 'react';
import Home from '../pages/Home';
import Collections from '../pages/Collections';
import CampaignsPage from '../pages/CampaignsPage';

export default [
  {
    id: 'home',
    name: 'Home',
    path: '/',
    component: (props: any) => <Home {...props} description="Home" />,
  },
  {
    id: 'collection',
    name: 'Collection',
    path: '/campaigns/:campaignUUID/nfts',
    auth: true,
    component: (props: any) => <Collections {...props} description="Collections" />,
  },
  {
    id: 'campaigns',
    name: 'Campaigns',
    path: '/campaigns',
    component: (props: any) => <CampaignsPage {...props} description="Campaigns Page" />,
  },
];
