import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { useWeb3React } from '@web3-react/core';
import useOnClickOutside from 'use-onclickoutside';
import { Logger } from '../../../../api/logger';
import { chains, chainMapping } from '../../../../configs/evmWalletConfigs';
import { StyledContainer, StyledButton, StyledDropdownContainer, StyledItem, StyledDescription } from './Styles';

const getSwitchNetworks = (chainId) => {
  const isApplicationMainnet = (process.env.REACT_APP_NETWORK || 'TESTNET') === 'MAINNET';
  const availableChains = chains.filter(
    (chain) =>
      chain.id !== chainId && (isApplicationMainnet ? chain.network === 'mainnet' : chain.network === 'testnet'),
  );
  const aorusAvailableChains = availableChains.filter((chain) => chain.name === 'eth' || chain.name === 'polygon');

  return aorusAvailableChains.map((chain) => {
    const { display, id, rpc, metamaskDefaultRPC, nativeCurrency } = chain;
    return {
      id,
      url: metamaskDefaultRPC || rpc,
      chainName: display,
      nativeCurrency,
    };
  });
};

const NetworkSwitchButton = () => {
  const { connector, chainId } = useWeb3React();
  const [showDropdown, setDropdown] = useState(false);
  const ref = useRef(null);
  const switchNetworks = getSwitchNetworks(chainId);
  const history = useHistory();

  const handleTriggerClick = () => {
    setDropdown(!showDropdown);
  };

  useOnClickOutside(ref, () => setDropdown(false));

  const getItemClickHandler = (chain) => async () => {
    const { id } = chain;
    await connector.activate(id);
    history.push('/');
  };
  return (
    <StyledContainer ref={ref}>
      <StyledButton onClick={handleTriggerClick}>
        {(chainMapping[chainId] || {}).display || 'Select Network'}
      </StyledButton>
      {showDropdown && (
        <StyledDropdownContainer>
          <StyledDescription>
            {`Current: ${(chainMapping[chainId] || {}).display || 'Select Network'}`}
          </StyledDescription>
          <StyledDescription>Choose Network: </StyledDescription>
          {switchNetworks.map((chain) => (
            <StyledItem onClick={getItemClickHandler(chain)}>{chain.chainName}</StyledItem>
          ))}
        </StyledDropdownContainer>
      )}
    </StyledContainer>
  );
};

export default NetworkSwitchButton;
