import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useGetUtilityCollections } from '@/api/getUtilityCollections';
import { useVenlyWalletContext } from '@/providers/venlyWalletProvider';
import useApplicationContext from '../../providers/applicationContext';
import CampaignCard from '../../components/CampaignCard';
import Page from '../Page';
import { XS_QUERY } from '../../breakpoints';
import { IUtilityCollectionById } from './type';

const StyledContainer = styled.div`
  max-width: 1200px;
  margin: auto;
  margin-top: 48px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;

  @media ${XS_QUERY} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;
  }
`;

const StyledTitle = styled.div`
  color: white;
  text-align: center;
  margin-top: 48px;
  font-size: 24px;
`;

const StyledWarn = styled.div`
  font-size: 24px;
  margin-top: 48px;
  text-align: center;
  color: white;
`;

const CampaignPage = () => {
  const [utilityCollections, setUtilityCollections] = useState<IUtilityCollectionById[]>([]);
  const { currentChainType } = useApplicationContext();
  const { chainId, account } = useWeb3React();
  const { venlyWallet } = useVenlyWalletContext();

  const { loadingGetUtilityCollections, getUtilityCollections } = useGetUtilityCollections();

  const loading = loadingGetUtilityCollections;
  const address = account || venlyWallet?.address || '';

  useEffect(() => {
    if (chainId) {
      getUtilityCollections({ type: currentChainType, chainId: Number(chainId) || 137 })
        .then((data) => {
          setUtilityCollections(data as IUtilityCollectionById[]);
        })
        .catch((err) => {
          setUtilityCollections([]);
        });
    }
  }, [currentChainType, address, chainId]);

  return (
    <Page>
      <StyledTitle>Current Events</StyledTitle>
      {!loading && utilityCollections.length === 0 && <StyledWarn>No events.</StyledWarn>}
      <StyledContainer>
        {utilityCollections.map(({ id, name, description, mediaUrls, startTime, endTime }) => (
          <CampaignCard
            key={id}
            uuid={id}
            name={name}
            description={description}
            imageUrl={mediaUrls.image}
            startTime={startTime}
            endTime={endTime}
            loading={loading}
          />
        ))}
      </StyledContainer>
    </Page>
  );
};

export default CampaignPage;
