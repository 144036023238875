import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import QRCode from 'qrcode.react';
import color from '../../styles/color';
import backgroundImage from '../../assets/background.svg';
import logoImage from '../../assets/logo_white.svg';

const StyledContainer = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  background: ${color.purple};
  width: 100%;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px;
`;

const StyledLogoContainer = styled.div`
  margin: auto;
`;

const StyledTitle = styled.div`
  margin-top: 24px;
  font-size: 24px;
  font-weight: bold;
`;

const StyledDescription = styled.div`
  font-size: 14px;
  margin-top: 12px;
`;

const StyledQRCodeContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px;
`;

const StyledQRCodeWrapper = styled.div<{ size: number; space: number; qrString: string }>`
  width: ${({ size, space }) => size + space * 2}px;
  height: ${({ size, space }) => size + space * 2}px;
  padding: ${({ space }) => space}px;
  background-color: white;
`;

const StyledHash = styled.div`
  text-align: right;
  color: ${color.gray};
  font-size: 8px;
`;

const QRCODE_SIZE = 150;
const QRCODE_SPACE = 12;

type Props = {
  qrString: string;
  event: {
    eventDescription: string;
    eventName: string;
  };
  hash: string;
  type: string | number;
};

const Ticket = ({ qrString, event, hash, type }: Props) => {
  const { eventDescription, eventName } = event;
  return (
    <StyledContainer>
      <StyledWrapper>
        <StyledLogoContainer>
          <img alt="logo" src={logoImage} />
        </StyledLogoContainer>
        <StyledTitle>
          <ReactMarkdown linkTarget="_blank">{eventName}</ReactMarkdown>
        </StyledTitle>
        <StyledDescription>
          <ReactMarkdown linkTarget="_blank">{eventDescription}</ReactMarkdown>
        </StyledDescription>
        <StyledQRCodeContainer>
          {qrString && Number(type) === 0 && (
            <StyledQRCodeWrapper size={QRCODE_SIZE} space={QRCODE_SPACE} qrString={qrString}>
              <QRCode id="passport" value={qrString} width="25%" size={QRCODE_SIZE} level="L" />
            </StyledQRCodeWrapper>
          )}
          {qrString && Number(type) === 2 && (
            <div>
              <ReactMarkdown>{qrString}</ReactMarkdown>
            </div>
          )}
        </StyledQRCodeContainer>
        <StyledHash>{hash}</StyledHash>
      </StyledWrapper>
    </StyledContainer>
  );
};

export default Ticket;
