import React, { createContext, useState, useContext } from 'react';
import Cookies from 'js-cookie';
import { useLogin } from '../api/auth/login';
import { Logger } from '../api/logger';

type Props = {
  children: React.ReactNode;
};

interface ILoginParams {
  type: string;
  address: string;
  signature: string;
}

interface ContextType {
  authToken: string;
  authRefreshToken: string;
  loginError: string;
  login: (params: ILoginParams) => Promise<void>;
  resetAuth: () => void;
}

const defaultAuth = {
  authToken: '',
  authRefreshToken: '',
};

const AuthContext = createContext<ContextType>({
  ...defaultAuth,
  loginError: '',
  login: () => Promise.resolve(),
  resetAuth: () => {},
});

const useAuthProvider = () => {
  const [auth, setAuth] = useState(defaultAuth);
  const [loginError, setLoginError] = useState('');
  const { authLogin } = useLogin();

  const login = async ({ type, address, signature }: ILoginParams) => {
    try {
      const data = await authLogin({ type, address, signature });
      setAuth({
        authToken: data.token,
        authRefreshToken: data.refreshToken,
      });
    } catch (e) {
      Logger.error({ error: e as Error, message: 'login error' });
      setLoginError(JSON.stringify(e));
    }
  };

  const resetAuth = () => {
    Cookies.remove('token');
    Cookies.remove('refreshToken');

    // for wax
    Cookies.remove('wax:token');
    Cookies.remove('wax:refreshToken');
    setAuth(defaultAuth);
  };

  return {
    ...auth,
    login,
    resetAuth,
    loginError,
  };
};

export const AuthProvider = ({ children }: Props) => {
  const auth = useAuthProvider();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
