/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import isNil from 'lodash/isNil';
import styled from 'styled-components';
import { useWeb3React } from '@web3-react/core';
import { useGetUtilityCollection } from '@/api/getUtilityCollection';
import { useGetNFTByUtilityCollectionId } from '@/api/getNFTByUtilityCollectionId';
import useApplicationContext from '../providers/applicationContext';
import { useAuthContext } from '../providers/authProvider';
import CollectionCard from '../components/CollectionCard';
import CollectionModal from '../components/CollectionCard/CollectionModal';
import color from '../styles/color';
import Page from './Page';
import { useVenlyWalletContext } from '../providers/venlyWalletProvider';

const StyledContainer = styled.div`
  margin: auto;
  max-width: 800px;
  display: flex;
  flex-direction: column;
`;

const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const StyledTitle = styled.div`
  color: white;
  text-align: center;
  margin-top: 48px;
  font-size: 24px;
  padding: 0 6px;
`;

const StyledDescription = styled.div`
  color: white;
  text-align: left;
  margin-top: 12px;
  font-size: 14;
  padding: 0 6px;
  color: ${color.gray};
`;

const StyledWarn = styled.div`
  font-size: 24px;
  margin-top: 48px;
  text-align: center;
  color: white;
`;

interface IModalInfo {
  tokenId: string | null;
  name: string | null;
}

const defaultModalInfo: IModalInfo = {
  tokenId: null,
  name: null,
};

interface IUtilityCollectionNFTs {
  image: string;
  name: string;
  description: string;
  tokenId: string;
  contractAddress: string;
  chainId: number;
}

interface IUtilityCollectionById {
  id: string;
  name: string;
  description: string;
  mediaUrls: {
    image: string;
    video: string;
  };
  startTime: string;
  endTime: string;
  contractAddresses: string[];
  redeemedDescription: string;
  maximumRedemptions: number;
  redeemedCount: number;
}

const Collections = () => {
  const { currentChainType } = useApplicationContext();
  const { venlyUser, venlyWallet } = useVenlyWalletContext();
  const { authToken } = useAuthContext();
  const { account, isActive, chainId: metamaskChainId } = useWeb3React();
  const [nfts, setNFTs] = useState<IUtilityCollectionNFTs[]>([]);
  const [modalInfo, setModalInfo] = useState<IModalInfo>(defaultModalInfo);
  const [utilityCollection, setUtilityCollection] = useState<IUtilityCollectionById | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refreshKey, setRefreshKey] = useState<number>(0);

  const { campaignUUID } = useParams<{ campaignUUID?: string }>();

  const { getUtilityCollection, loadingGetUtilityCollection } = useGetUtilityCollection();
  const { getNFTsByUtilityCollectionId, loadingGetNFTsByUtilityCollectionId } = useGetNFTByUtilityCollectionId();

  const loading = loadingGetUtilityCollection || loadingGetNFTsByUtilityCollectionId;
  const address = account || venlyWallet?.address || '';
  const chainId = metamaskChainId;

  const handleCardClick = (nft: IModalInfo) => {
    setModalInfo(nft);
  };

  const refreshNfts = () => {
    setRefreshKey((prev) => prev + 1);
  };

  const shouldOpenModal = !isNil(modalInfo.tokenId);

  useEffect(() => {
    if (address && campaignUUID) {
      getUtilityCollection({ type: 'evm', chainId: Number(chainId), utilityCollectionId: campaignUUID })
        .then((data) => setUtilityCollection((data as IUtilityCollectionById) || {}))
        .catch((e) => {
          setUtilityCollection(undefined);
        });
    }
  }, [address, campaignUUID, chainId]);

  useEffect(() => {
    if (address && utilityCollection && campaignUUID) {
      getNFTsByUtilityCollectionId({
        type: 'evm',
        chainId: Number(chainId),
        utilityCollectionId: campaignUUID,
      })
        .then(async (rawNfts) => {
          setIsLoading(true);
          let promises: Promise<IUtilityCollectionNFTs>[] = [];
          utilityCollection.contractAddresses.forEach((contractAddr) => {
            const contractNfts = rawNfts[contractAddr];
            const arr = Object.keys(contractNfts).map(async (tokenId) => {
              const response = await fetch(contractNfts[tokenId]);
              const result = await response.json();
              return {
                ...result,
                image: result.image.replace('ipfs://', 'https://redreamer.infura-ipfs.io/ipfs/'),
                tokenId,
                contractAddress: contractAddr,
              };
            });
            promises = [...arr, ...promises];
          });

          const response = await Promise.allSettled(promises);
          const results = response
            .map((item) => {
              if (item.status === 'fulfilled') {
                return item.value;
              }
              return null;
            })
            .filter((item) => !!item) as IUtilityCollectionNFTs[];

          setNFTs(results);
        })
        .catch((e) => {
          setNFTs([]);
        })
        .finally(() => setIsLoading(false));
    }
  }, [refreshKey, utilityCollection, address]);

  return (
    <Page>
      <StyledContainer>
        <StyledTitle>{loadingGetUtilityCollection ? <Skeleton width={200} /> : utilityCollection?.name}</StyledTitle>
        <StyledDescription>
          {loadingGetUtilityCollection ? (
            <Skeleton />
          ) : (
            <ReactMarkdown>{utilityCollection?.description || ''}</ReactMarkdown>
          )}
        </StyledDescription>
        {!loadingGetNFTsByUtilityCollectionId && nfts.length === 0 && (
          <StyledWarn>you don't have eligible NFTs for this event.</StyledWarn>
        )}
        <StyledWrapper>
          {nfts.map((nft, id) => (
            <CollectionCard key={nft.tokenId} nft={nft} loading={loading} onClick={handleCardClick} />
          ))}
        </StyledWrapper>
      </StyledContainer>
      <CollectionModal
        opened={shouldOpenModal}
        onClose={() => setModalInfo(defaultModalInfo)}
        campaignName={utilityCollection?.name || ''}
        redeemedDescription={utilityCollection?.redeemedDescription || ''}
        nft={modalInfo}
      />
    </Page>
  );
};

export default Collections;
