import PropTypes from 'prop-types';

export const nftPropTypes = PropTypes.shape({
  artwork: PropTypes.string,
  campaignUUID: PropTypes.string,
  createdAt: PropTypes.string,
  deletedAt: PropTypes.string,
  id: PropTypes.string,
  image: PropTypes.string,
  isMinted: PropTypes.bool,
  isValid: PropTypes.bool,
  isRedeemable: PropTypes.bool,
  merchantId: PropTypes.string,
  metadata: PropTypes.shape({}),
  tags: PropTypes.arrayOf(PropTypes.string),
  tokenId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tokenName: PropTypes.string,
}).isRequired;

export const nftDefaultValue = {
  metadata: {},
  image: '',
};
