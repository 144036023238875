import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import BindingModal from '../../components/layouts/AccountInfo/BindingModal';
import SelectAccountModal from '../../components/SelectAccountModal';
import Button from '../../components/Button';
import Footer from '../../components/layouts/Footer';
import backgroundImage from '../../assets/background.png';
import backgroundMobileImage from '../../assets/background_mobile.png';
import compassImage from '../../assets/compass.svg';
import unbindingImage from '../../assets/unbinding.svg';
import color from '../../styles/color';
import { useAuthContext } from '../../providers/authProvider';
import getFeature from '../../featureSwitch';
import { XS_QUERY } from '../../breakpoints';
import Logo from './Logo';
import { useVenlyWalletContext } from '../../providers/venlyWalletProvider';
import useApplicationContext from '../../providers/applicationContext';

const StyledContainer = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  height: ${window.innerHeight}px;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${XS_QUERY} {
    background-image: url(${backgroundMobileImage});
    position: relative;
  }
`;

const StyledButtonContainer = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 48px;
`;

const StyledFilterButtonContainer = styled(StyledButtonContainer)`
  margin-top: 0;
  filter: drop-shadow(-2px 0px 0px ${color.purple}) drop-shadow(1px 1px 0px ${color.purple})
    drop-shadow(0px 1px 0px ${color.purple}) drop-shadow(1px -2px 0px ${color.purple});
`;

const StyledButton = styled(Button)<{ hollow?: boolean }>`
  margin: auto;
  width: 200px;
  background: ${({ hollow }) => (hollow ? '#000' : color.purple)};
  color: ${({ hollow }) => (hollow ? color.purple : color.white)};
  font-weight: bold;
  margin-bottom: 16px;
  padding: 0;

  &:hover {
    background: ${color.white};
    color: ${color.purple};
  }
`;

const StyledAllNFTs = styled(Link)`
  position: fixed;
  display: flex;
  align-items: center;
  color: ${color.yellow};
  top: 12px;
  right: 12px;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;

  &:before {
    content: '';
    background: url(${compassImage});
    width: 12px;
    height: 12px;
    margin-right: 6px;
  }
`;

const StyledUnbind = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  color: ${color.yellow};
  top: 12px;
  left: 12px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;

  &:before {
    content: '';
    background: url(${unbindingImage});
    width: 12px;
    height: 12px;
    margin-right: 6px;
  }

  div {
    color: ${color.yellow};
    max-width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledSuccessMessage = styled.div`
  margin-top: 48px;
  font-size: 18px;
  color: ${color.yellow};
  text-align: center;
  font-weight: bold;
`;

const StyledTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-top: 72px;
  margin-bottom: 19px;
  color: white;
  text-align: center;

  @media ${XS_QUERY} {
    margin-top: 48px;
  }
`;

const StyledSubTitle = styled.div`
  font-size: 16px;
  color: white;
  text-align: center;
`;

const StyledAutoSpace = styled.div`
  flex: 1;
  width: 100%;
`;

const Home = () => {
  const [bindingModal, setBindingModal] = useState(false);
  const [selectModal, setSelectModal] = useState(false);
  const [showSignButton, setShowSignButton] = useState(false);
  const { account, connector } = useWeb3React();
  const { loginError, authToken, resetAuth } = useAuthContext();
  const history = useHistory();
  const { venlyUser, venlyWallet: selectVenlyWallet, venlyWallets, venlyDeactivate } = useVenlyWalletContext();
  const { isCancelWallet, setTriggerSignWallet, setIsCancelWallet } = useApplicationContext();

  const handleButtonClick = () => {
    setBindingModal(true);
  };

  const handleSginButton = () => {
    setTriggerSignWallet(true);
  };

  const handleBindingModalClose = () => {
    setBindingModal(false);
    setTriggerSignWallet(false);
  };

  const handleUnbind = async () => {
    if (connector) {
      await connector.resetState();
      localStorage.clear();
      connector.provider = undefined;
      (connector as typeof connector & { eagerConnection: any }).eagerConnection = undefined;
    }
    venlyDeactivate();
    resetAuth();
    setShowSignButton(false);
    setIsCancelWallet(false);
  };

  const walletMessage = () => {
    if (loginError) {
      return (
        <>
          <StyledTitle>Please try again</StyledTitle>
          <StyledSubTitle>{loginError}</StyledSubTitle>
        </>
      );
    }
    if (isCancelWallet) {
      return (
        <>
          <StyledTitle>Canceled</StyledTitle>
          <StyledSubTitle>Looks like you canceled the signing process.</StyledSubTitle>
        </>
      );
    }
    return (
      <>
        <StyledTitle>Wallet Connected</StyledTitle>
        <StyledSubTitle>Sign the message with your wallet in order to continue.</StyledSubTitle>
      </>
    );
  };

  const getAddress = () => {
    if (venlyUser) return selectVenlyWallet?.address || '';
    return account;
  };

  const address = getAddress();

  useEffect(() => {
    if (venlyUser && !selectVenlyWallet && venlyWallets.length > 1) {
      setBindingModal(false);
      setSelectModal(true);
    }
  }, [venlyUser, venlyWallets, selectVenlyWallet]);

  useEffect(() => {
    if (address) {
      setShowSignButton(true);
    }
  }, [address]);

  useEffect(() => {
    if (!account && !connector) {
      setShowSignButton(false);
    }
  }, [account, connector]);

  return (
    <StyledContainer>
      {address && authToken && (
        <StyledUnbind onClick={handleUnbind}>
          Disconnect:&nbsp;
          <div>
            <abbr title={address}>{address}</abbr>
          </div>
        </StyledUnbind>
      )}
      <StyledAutoSpace>
        <Logo />
        {!authToken && !showSignButton && (
          <StyledButtonContainer>
            <StyledButton type="button" hoverBackgroundColor={color.yellowSecond} onClick={handleButtonClick}>
              Connect Wallet
            </StyledButton>
          </StyledButtonContainer>
        )}
        {!authToken && showSignButton && (
          <>
            {walletMessage()}
            <StyledButtonContainer>
              <StyledButton type="button" hoverBackgroundColor={color.yellowSecond} onClick={handleSginButton}>
                Sign
              </StyledButton>
            </StyledButtonContainer>
            <StyledFilterButtonContainer>
              <StyledButton type="button" hollow hoverBackgroundColor={color.yellowSecond} onClick={handleUnbind}>
                Disconnect
              </StyledButton>
            </StyledFilterButtonContainer>
          </>
        )}
        {authToken && (
          <StyledButtonContainer>
            <StyledButton
              type="button"
              hoverBackgroundColor={color.yellowSecond}
              onClick={() => {
                history.push('/campaigns');
              }}
            >
              My Collections
            </StyledButton>
          </StyledButtonContainer>
        )}
        {authToken && <StyledSuccessMessage>Connecting Wallet...Success!</StyledSuccessMessage>}
      </StyledAutoSpace>
      <BindingModal hasBackDrop={false} opened={bindingModal} onClose={handleBindingModalClose} />
      <SelectAccountModal hasBackDrop={false} opened={selectModal} onClose={() => setSelectModal(false)} />
      <Footer color={color.white} />
    </StyledContainer>
  );
};

export default Home;
