import styled from 'styled-components';
import React from 'react';

import logo from '../../assets/logo.svg';
import { XS_QUERY } from '../../breakpoints';

const StyledContainer = styled.div`
  margin: auto;
  text-align: center;
  padding-top: 180px;
  width: 475px;

  & > img {
    width: 100%;
  }

  @media ${XS_QUERY} {
    padding-top: 120px;
    width: 300px;
  }
`;

const Logo = () => (
  <StyledContainer>
    <img alt="logo" src={logo} />
  </StyledContainer>
);

export default Logo;
