import moment from 'moment';
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import Skeleton from 'react-loading-skeleton';
import logoImage from '../../assets/logo.svg';
import color from '../../styles/color';
import { XS_QUERY } from '../../breakpoints';

const StyledContainer = styled.div<{ isLoading: boolean }>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 12px;
  width: 100%;
  cursor: ${({ isLoading }) => (isLoading ? 'not-allowed' : 'pointer')};
  box-shadow: 0 10px 35px rgb(255 255 255 / 6%);
  color: white;
  background-color: ${color.blackLight};
`;

const StyledImageContainer = styled.div`
  img {
    width: 100%;
    object-fit: contain;
    @media ${XS_QUERY} {
      width: 100%;
    }
  }
  margin-bottom: 12px;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTime = styled.div`
  font-size: 12px;
  height: 16px;
  color: ${color.gray};
`;

const StyledTitle = styled.div`
  overflow: hidden;
  font-size: 18px;
  font-weight: bold;
  height: 24px;
  max-height: 48px;
  height: 48px;
  line-height: 24px;
  margin-bottom: 6px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
`;

const StyledDescription = styled.div`
  overflow: hidden;
  font-size: 14px;
  line-height: 14px;
  max-height: 42px;
  height: 42px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all;
`;

type Props = {
  uuid: string;
  name: string;
  description: string;
  imageUrl: string;
  startTime: string;
  endTime: string;
  loading: boolean;
}

const CampaignCard = ({
  uuid,
  name = '',
  description = '',
  imageUrl = '',
  startTime = '',
  endTime = '',
  loading,
}: Props) => {
  const [height, setHeight] = useState<number | undefined>();
  const imageRef = useRef<HTMLImageElement | null>(null);
  const history = useHistory();

  const handleClick = () => {
    if (loading) return;
    history.push(`/campaigns/${uuid}/nfts`);
  };

  useEffect(() => {
    if (imageRef.current) {
      const { width } = imageRef.current.getBoundingClientRect();
      setHeight(width);
    }
  }, [imageRef.current]);

  return (
    <StyledContainer onClick={handleClick} isLoading={loading}>
      <StyledImageContainer>
        {loading ? <Skeleton height={height} /> : <img ref={imageRef} height={height} alt="cover" src={imageUrl || logoImage} />}
      </StyledImageContainer>
      <StyledContent>
        <StyledTime>
          {loading ? <Skeleton width={100} /> : `${moment(startTime).format('YYYY/M/D')}-${moment(endTime).format('YYYY/M/D')}`}
        </StyledTime>
        <StyledTitle>
          {loading ? <Skeleton count={1} /> : name}
        </StyledTitle>
        <StyledDescription>
          {loading ? <Skeleton count={1} /> : description}
        </StyledDescription>
      </StyledContent>
    </StyledContainer>
  );
};

export default CampaignCard;
