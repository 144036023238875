import React from 'react';
import { useHistory } from 'react-router';
import { useWeb3React } from '@web3-react/core';
import { StyledHeader, StyledNav, StyledContainer, StyledImage, StyledAllNFTs, StyledUnbind } from './Styles';
import { useAuthContext } from '../../../providers/authProvider';
import IconImage from '../../../assets/logo_white.svg';
import getFeature from '../../../featureSwitch';
import { NetworkSwitchButton } from './NetworkSwitchButton';
import { useVenlyWalletContext } from '../../../providers/venlyWalletProvider';

const Navbar = () => {
  const { account, connector } = useWeb3React();
  const { venlyUser, venlyWallet, venlyDeactivate } = useVenlyWalletContext();
  const { resetAuth } = useAuthContext();
  const history = useHistory();
  const handleLogoClick = () => {
    history.push('/');
  };

  const handleUnbind = async () => {
    if (connector) {
      await connector.resetState();
      localStorage.clear();
      connector.provider = undefined;
      (connector as typeof connector & { eagerConnection: any }).eagerConnection = undefined;
    }
    venlyDeactivate();
    resetAuth();
  };

  const getAddress = () => {
    if (venlyUser) return venlyWallet?.address || '';
    return account;
  };

  return (
    <StyledHeader>
      <StyledNav>
        {getFeature('showExploreNFTLink') && <StyledAllNFTs to="/nfts">Explore NFTs</StyledAllNFTs>}
        {getAddress() && (
          <StyledUnbind onClick={handleUnbind}>
            Unbinding Wallet:&nbsp;
            <div>
              <abbr title={getAddress()}>{getAddress()}</abbr>
            </div>
          </StyledUnbind>
        )}
        <StyledContainer>
          <StyledImage onClick={handleLogoClick} alt="redreamer logo" src={IconImage} />
        </StyledContainer>
        {
          // only support switch network for evm
          account && <NetworkSwitchButton />
        }
      </StyledNav>
    </StyledHeader>
  );
};

export default Navbar;
