import React from 'react';
import { useLocation } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import styled from 'styled-components';
import Navbar from '../components/layouts/Navbar';
import Footer from '../components/layouts/Footer';
import color from '../styles/color';
import useApplicationContext from '../providers/applicationContext';
import backgroundImage from '../assets/background.png';
import backgroundMobileImage from '../assets/background_mobile.png';
import { XS_QUERY } from '../breakpoints';

const StyledContainer = styled.div`
  font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: ${color.blackLight};
  min-height: ${window.innerHeight}px;
  position: relative;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${XS_QUERY} {
    background-image: url(${backgroundMobileImage});
    position: relative;
  }
`;

const StyledMainnetWarning = styled.div`
  position: fixed;
  color: red;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background: ${color.yellow};
  text-align: center;
  padding: 3px;
  opacity: 0.6;
`;

const StyledAutoSpace = styled.div`
  flex: 1;
  width: 100%;
`;

type Props = {
  children: React.ReactNode;
}

const Page = ({ children }: Props) => {
  const { account } = useWeb3React();
  const { pathname } = useLocation();
  const { isMainnet } = useApplicationContext();
  const isWarningPath = !pathname.includes('/nft');
  return (
    <StyledContainer>
      <StyledAutoSpace>
        <Navbar />
        {children}
      </StyledAutoSpace>
      <Footer />
      {!isMainnet && account && isWarningPath && (
        <StyledMainnetWarning>
          WARNING: You are not on Mainnet now!
        </StyledMainnetWarning>
      )}
    </StyledContainer>
  );
};

export default Page;
