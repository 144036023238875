import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import color from '../../styles/color';
import Modal from '../Modal/Modal';
import Ticket from '../Ticket';
import NFTEvent from './NFTEvent';
import { nftPropTypes, nftDefaultValue } from './propTypes';

const StyledContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
`;

const StyledQRContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const CollectionModal = ({ opened, onClose, nft, campaignName, redeemedDescription }) => {
  const [qrString, setQRString] = useState();
  const [type, setType] = useState(0);
  const [showTicket, setShowTicket] = useState(false);
  const [event, setEvent] = useState({});
  const handleClose = () => {
    setShowTicket(false);
    onClose();
  };
  const getChildCompoent = () =>
    showTicket ? (
      <StyledQRContainer>
        <Ticket qrString={qrString} event={event} type={type} />
      </StyledQRContainer>
    ) : (
      <NFTEvent
        nft={nft}
        campaignName={campaignName}
        redeemedDescription={redeemedDescription}
        setEvent={(q) => {
          setEvent(q);
        }}
        setQRString={(q) => {
          setQRString(q);
        }}
        setType={setType}
        setShowTicket={setShowTicket}
      />
    );

  const getBackgroundColor = () => (showTicket ? color.white : color.blackLight);

  return (
    <Modal
      opened={opened}
      onClose={handleClose}
      backgroundColor={getBackgroundColor()}
      closeColor={color.purple}
      width="60%"
    >
      <StyledContainer>{getChildCompoent()}</StyledContainer>
    </Modal>
  );
};

CollectionModal.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  nft: nftPropTypes,
  campaignName: PropTypes.string,
  redeemedDescription: PropTypes.string,
};

CollectionModal.defaultProps = {
  opened: false,
  nft: nftDefaultValue,
  campaignName: undefined,
  redeemedDescription: undefined,
};

export default CollectionModal;
