import { SecretType } from '@venly/connect';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';

// mapping mainnet and testnet to chain alias
export const chains = [
  {
    id: 1,
    name: 'eth',
    rpc: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    metamaskDefaultRPC: 'https://mainnet.infura.io/v3/',
    enabled: true,
    network: 'mainnet',
    display: 'Ethereum Mainnet',
    venlyName: SecretType.ETHEREUM,
  },
  {
    id: 3,
    name: 'eth',
    rpc: 'https://ropsten.infura.io/v3/d9044d96244b426fb3bbe68c46c848cb',
    metamaskDefaultRPC: 'https://ropsten.infura.io/v3/',
    enabled: false,
    network: 'testnet',
    display: 'Ethereum Testnet - Ropsten',
    venlyName: SecretType.ETHEREUM,
  },
  {
    id: 4,
    name: 'eth',
    rpc: 'https://rinkeby.infura.io/v3/d9044d96244b426fb3bbe68c46c848cb',
    metamaskDefaultRPC: 'https://rinkeby.infura.io/v3/',
    enabled: false,
    network: 'testnet',
    display: 'Ethereum Testnet - Rinkeby',
    venlyName: SecretType.ETHEREUM,
  },
  {
    id: 5,
    name: 'eth',
    rpc: 'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    metamaskDefaultRPC: 'https://goerli.infura.io/v3/',
    enabled: true,
    network: 'testnet',
    display: 'Ethereum Testnet - Goerli',
    nativeCurrency: {
      name: 'Goerli ETH',
      symbol: 'gorETH',
      decimals: 18,
    },
    venlyName: SecretType.ETHEREUM,
  },
  {
    id: 18,
    name: 'tt',
    rpc: 'https://testnet-rpc.thundercore.com',
    enabled: true,
    network: 'testnet',
    display: 'ThunderCore Testnet',
    nativeCurrency: {
      name: 'Testnet TT',
      symbol: 'TST',
      decimals: 18,
    },
  },
  {
    id: 56,
    name: 'bnb',
    rpc: 'https://bsc-dataseed1.binance.org',
    enabled: true,
    network: 'mainnet',
    display: 'Binance Smart Chain Mainnet',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
  },
  {
    id: 97,
    name: 'bnb',
    rpc: 'https://data-seed-prebsc-1-s1.binance.org:8545',
    enabled: true,
    network: 'testnet',
    display: 'Binance Smart Chain Testnet',
    nativeCurrency: {
      name: 'Testnet BNB',
      symbol: 'tBNB',
      decimals: 18,
    },
  },
  {
    id: 108,
    name: 'tt',
    rpc: 'https://mainnet-rpc.thundercore.com',
    enabled: true,
    network: 'mainnet',
    display: 'ThunderCore Mainnet',
    nativeCurrency: {
      name: 'TT',
      symbol: 'TT',
      decimals: 18,
    },
  },
  {
    id: 137,
    name: 'polygon',
    rpc: 'https://polygon-rpc.com',
    enabled: true,
    network: 'mainnet',
    display: 'Polygon Mainnet',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    venlyName: SecretType.MATIC,
  },
  {
    id: 80001,
    name: 'polygon',
    rpc: 'https://rpc-mumbai.maticvigil.com',
    enabled: true,
    network: 'testnet',
    display: 'Polygon Testnet - Mumbai',
    nativeCurrency: {
      name: 'Testnet MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    venlyName: SecretType.MATIC,
  },
].filter((c) => c.enabled);

export const chainMapping = keyBy(chains, 'id');

export const walletRpcConnectConfig = mapValues(chainMapping, (c) => c.rpc);
