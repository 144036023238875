import styled from 'styled-components';
import { XS_QUERY } from '../../../../breakpoints';
import color from '../../../../styles/color';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  right: 16px;
  top: 16px;
  justify-content: center;
  font-size: 12px;
`;

export const StyledSwitch = styled.div`
  color: ${color.yellow};
  margin-right: 3px;
`;

export const StyledButton = styled.button`
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 3px;
  box-sizing: border-box;
  background: transparent;
  border: 1px ${color.white} solid;
  color: ${color.white};
  cursor: pointer;

  &:hover {
    background: ${color.white};
    color: ${color.purple};
  }

  @media ${XS_QUERY} {
    max-width: 80px;
  }
`;

export const StyledDropdownContainer = styled.div`
  border: 1px solid ${color.gray};
  padding: 8px;
  position: relative;
  top: 12px;
  background-color: black;
`;

export const StyledItem = styled.div`
  cursor: pointer;
  padding: 8px;
  color: ${color.purple};

  &:hover {
    background: ${color.purple};
    color: ${color.white};
  }
`;

export const StyledDescription = styled.div`
  color: white;
  margin-bottom:  8px;
`;
