import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  StyledContainer,
  StyledImageContainer,
  StyledImage,
  StyledVideo,
} from './Styles';
import { nftPropTypes, nftDefaultValue } from './propTypes';
import redreamerIcon from '../../assets/logo_icon.svg';
import { getImageUrl } from '../../utils';

const CollectionCard = ({
  nft, onClick, loading,
}) => {
  const imageRef = useRef();
  const [height, setHeight] = useState();

  const { image = '', video } = nft;

  const isDisableCache = image.startsWith('https://nft-taipei-2022.dynamics.art');

  const handleClick = () => {
    if (loading) return;
    onClick(nft);
  };

  const renderAsset = () => {
    if (video) {
      return (
        <StyledVideo
          autoPlay
          loop
          muted
        >
          <source src={video} type="video/mp4" />
        </StyledVideo>
      );
    }
    return (
      <StyledImage
        height={height}
        ref={imageRef}
        alt="nft"
        objectFit={!image && 'contain'}
        src={getImageUrl(image, isDisableCache) || redreamerIcon}
      />
    );
  };

  useEffect(() => {
    if (imageRef.current) {
      const { width } = imageRef.current.getBoundingClientRect();
      setHeight(width);
    }
  }, [imageRef.current]);

  return (
    <StyledContainer
      onClick={handleClick}
      isLoading={loading}
    >
      <Skeleton />
      <StyledImageContainer>
        {
          loading ? <Skeleton width="100%" height={height} /> : renderAsset()
        }
      </StyledImageContainer>
    </StyledContainer>
  );
};

CollectionCard.propTypes = {
  nft: nftPropTypes,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

CollectionCard.defaultProps = {
  nft: nftDefaultValue,
};

export default CollectionCard;
