import React from 'react';
import styled from 'styled-components';
import Spinner from '../Spinner';
import { StyledButton, StyledContent } from './Styles';
import color from '../../styles/color';

const StyledChainName = styled.div`
  font-weight: normal;
  font-size: 90%;
`;

type Props = {
  activating?: boolean;
  connected?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  name?: string;
  subName?: string;
}

const ConnectButton = ({
  activating = false,
  connected = false,
  disabled = false,
  onClick = () => {},
  name = undefined,
  subName = undefined,
}: Props) => (
  <StyledButton
    disabled={disabled}
    hoverColor={color.purple}
    hoverBackgroundColor={color.white}
    onClick={() => onClick()}
  >
    <StyledContent>
      {activating && <Spinner color="black" style={{ height: '25%', marginLeft: '-6px' }} />}
      {connected && (
        <span role="img" aria-label="check">
          ✅
        </span>
      )}
    </StyledContent>
    <div>{name}</div>
    <StyledChainName>{subName}</StyledChainName>
  </StyledButton>
);

export default ConnectButton;
