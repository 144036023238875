import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { SkeletonTheme } from 'react-loading-skeleton';
import { ApplicationProvider } from './providers/applicationContext';
import { AuthProvider } from './providers/authProvider';
import Router from './routes';
import color from './styles/color';

const App = () => (
  <BrowserRouter>
    <AuthProvider>
      <ApplicationProvider>
        <SkeletonTheme
          baseColor={color.gray}
          highlightColor={color.purple}
        >
          <Router />
        </SkeletonTheme>
      </ApplicationProvider>
    </AuthProvider>
  </BrowserRouter>
);

export default App;
