/* eslint-disable react/forbid-prop-types */
import get from 'lodash/get';
import React, { createContext, useState, useEffect, useContext } from 'react';
import { SecretType } from '@venly/connect';
import { useWeb3React } from '@web3-react/core';
import Web3 from 'web3';
import { useHistory } from 'react-router';
import { useAuthContext } from './authProvider';
import { Logger } from '../api/logger';
import { signMessage, signTypedData } from '../rpc';
import { useInactiveListener } from '../hooks/useInactiveListener';
import { chainMapping } from '../configs/evmWalletConfigs';
import { useVenlyWalletContext } from './venlyWalletProvider';
import { useGetSignInMessage } from '../api/auth/getSignInMessage';

interface AppError extends Error {
  code: string;
  error: string;
}

type Props = {
  children: React.ReactNode;
};

interface ContextType {
  web3?: Web3;
  currentChainType: string;
  isMainnet: boolean;
  contracts?: object;
  addressConfigs?: object;
  tokenNames?: any[];
  triggerSignWallet: boolean;
  isCancelWallet: boolean;
  setIsCancelWallet: React.Dispatch<React.SetStateAction<boolean>>;
  setTriggerSignWallet: React.Dispatch<React.SetStateAction<boolean>>;
  redirectPath: string;
}

const ApplicationContext = createContext<ContextType>({
  web3: undefined,
  contracts: {},
  addressConfigs: {},
  tokenNames: [],
  currentChainType: '',
  isMainnet: false,
  triggerSignWallet: false,
  isCancelWallet: false,
  setIsCancelWallet: () => {},
  setTriggerSignWallet: () => {},
  redirectPath: '',
});

const Provider = ({ children }: Props) => {
  const { provider, chainId, account } = useWeb3React();
  const userAddress = (account || '').toLowerCase();
  const { venlyUser, venlyWallet, venlyEntity, venlySignTypedData } = useVenlyWalletContext();
  const history = useHistory();

  const [web3, setWeb3] = useState<Web3>();
  const [triggerSignWallet, setTriggerSignWallet] = useState<boolean>(false);
  const [isCancelWallet, setIsCancelWallet] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>('/');
  const [appError, setAppError] = useState<AppError | undefined>(undefined);

  const { login, resetAuth } = useAuthContext();
  const { getSignInMessage } = useGetSignInMessage();

  const getChainName = () => {
    if (userAddress) return get(chainMapping, [chainId as number, 'name']) || 'eth';
    if (venlyUser) return 'polygon';
    return '';
  };

  const isMainnet = get(chainMapping, [chainId as number, 'network']) === 'mainnet';

  useEffect(() => {
    if (provider) {
      const w = new Web3(provider as any);
      window.web3 = w;
      setWeb3(w);
    }
  }, [provider]);

  useInactiveListener();

  useEffect(() => {
    // evm login
    if (triggerSignWallet && provider && userAddress) {
      const handleLogin = async () => {
        history.push('/');
        resetAuth();
        try {
          const signInMessage: any = await getSignInMessage({ type: 'evm', address: userAddress });
          const signature = await signTypedData(provider, userAddress, signInMessage);
          login({ type: 'evm', address: userAddress, signature })
            .then(() => {
              history.push(redirectPath === '/' ? '/campaigns' : redirectPath);
            })
            .finally(() => {
              setTriggerSignWallet(false);
            });
        } catch (e) {
          const error = e as AppError;
          Logger.error({ error });
          setAppError(error);
        } finally {
          setTriggerSignWallet(false);
        }
      };
      handleLogin();
    }
  }, [triggerSignWallet, provider, userAddress]);

  useEffect(() => {
    // venly login
    const handleLogin = async () => {
      if (triggerSignWallet && venlyEntity && venlyUser && venlyWallet) {
        history.push('/');
        resetAuth();
        const walletAddr = (venlyWallet?.address || '').toLowerCase();
        const walletId = venlyWallet?.id || '';
        try {
          const signInMessage: any = await getSignInMessage({ type: 'evm', address: venlyWallet?.address || '' });
          const signature = await venlySignTypedData({ walletId, authorization: signInMessage });
          login({
            type: 'evm',
            address: walletAddr,
            signature: signature?.result.signature,
          })
            .then(() => {
              setIsCancelWallet(false);
              history.push('/campaigns');
            })
            .finally(() => {
              setTriggerSignWallet(false);
            });
        } catch (e) {
          const error = e as Error & { status: string };
          if (error.status === 'ABORTED') {
            setIsCancelWallet(true);
          }
          Logger.error({ error });
        }
      }
    };
    handleLogin();
  }, [triggerSignWallet, venlyEntity, venlyUser, venlyWallet]);

  useEffect(() => {
    history.replace('/');
  }, [userAddress, venlyUser, history]);

  const contexts = {
    web3,
    currentChainType: 'evm',
    isMainnet,
    triggerSignWallet,
    isCancelWallet,
    setIsCancelWallet,
    setTriggerSignWallet,
    redirectPath,
  };

  return <ApplicationContext.Provider value={contexts}>{children}</ApplicationContext.Provider>;
};

const useApplicationContext = () => useContext(ApplicationContext);

const ApplicationProvider = Provider;

export { ApplicationProvider };

export default useApplicationContext;
