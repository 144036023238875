import React, { createContext, useState, useContext } from 'react';
import { VenlyConnect, SecretType, Profile, Wallet, SignerResult } from '@venly/connect';

interface ISignMessage {
  walletId: string;
  data: string;
}

type Props = {
  children: React.ReactNode;
};

interface ContextType {
  venlyUser?: Profile;
  venlyWallets: Wallet[];
  venlyEntity?: VenlyConnect;
  loading: boolean;
  venlyWallet?: Wallet;
  venlyActivate: () => Promise<void>;
  venlyDeactivate: () => void;
  venlysignMessage: (params: ISignMessage) => Promise<SignerResult | undefined>;
  setVenlyWallet: React.Dispatch<React.SetStateAction<Wallet | undefined>>;
  venlySignTypedData: ({
    walletId,
    authorization,
  }: {
    walletId: string;
    authorization: any;
  }) => Promise<SignerResult | undefined>;
}

const VenlyWalletContext = createContext<ContextType>({
  venlyUser: undefined,
  venlyWallets: [],
  venlyEntity: undefined,
  loading: false,
  venlyWallet: undefined,
  venlyActivate: () => Promise.resolve(),
  venlyDeactivate: () => {},
  venlysignMessage: () => Promise.resolve(undefined),
  setVenlyWallet: () => {},
  venlySignTypedData: () => Promise.resolve(undefined),
});

const useVenlyWalletProvider = () => {
  const [user, setUser] = useState<Profile | undefined>();
  const [wallets, setWallets] = useState<Wallet[]>([]);
  const [venlyWallet, setVenlyWallet] = useState<Wallet | undefined>();
  const [loading, setLoading] = useState(false);
  const [venly, setVenly] = useState<VenlyConnect | undefined>();

  const isApplicationMainnet = (process.env.REACT_APP_NETWORK || 'TESTNET') === 'MAINNET';

  const activate = async () => {
    const clientId = 'REDREAMER_LAB';
    const venlyConnect = isApplicationMainnet ? new VenlyConnect(clientId) : new VenlyConnect(clientId, { environment: 'sandbox' });
    setVenly(venlyConnect);

    if (venlyConnect) {
      setLoading(true);
      venlyConnect.flows
        .getAccount(SecretType.MATIC)
        .then(async (account) => {
          const profile = await venlyConnect.api.getProfile();
          setUser(profile);

          const polygonWallets = account.wallets.filter((wallet) => wallet.secretType === SecretType.MATIC);
          setWallets(polygonWallets);
          if (polygonWallets.length === 1) {
            setVenlyWallet(account.wallets[0]);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const deactivate = () => {
    setLoading(false);
    setVenly(undefined);
    setUser(undefined);
    setWallets([]);
    venly?.logout();
  };

  const signMessage = async ({ walletId, data }: ISignMessage) => {
    const signer = venly?.createSigner();
    const signature = await signer?.signMessage({ walletId, secretType: SecretType.MATIC, data });
    return signature;
  };

  const signTypedData = async ({ walletId, authorization }: { walletId: string; authorization: any }) => {
    const { domain, types, message } = authorization;
    const signer = venly?.createSigner();
    const data = {
      domain,
      types,
      message,
      primaryType: 'ForwardRequest',
    };
    const params = {
      walletId,
      secretType: SecretType.MATIC,
      data,
    };
    const signature = await signer?.signEip712(params);
    return signature;
  };

  return {
    venlyActivate: activate,
    venlyDeactivate: deactivate,
    venlyUser: user,
    venlyWallets: wallets,
    loading,
    venlyEntity: venly,
    venlysignMessage: signMessage,
    venlySignTypedData: signTypedData,
    venlyWallet,
    setVenlyWallet,
  };
};

export const VenlyWalletProvider = ({ children }: Props) => {
  const value = useVenlyWalletProvider();
  return <VenlyWalletContext.Provider value={value}>{children}</VenlyWalletContext.Provider>;
};

export const useVenlyWalletContext = () => useContext(VenlyWalletContext);
