import styled from 'styled-components';
import { MD_AND_DOWN_QUERY } from '../../../breakpoints';

export const StyledContainer = styled.div`
  width: 100%;
  position: relative;
  height: 0;
  margin-top: 50px;

  @media ${MD_AND_DOWN_QUERY} {
    height: 146px;
  }
`;

export const StyledContent = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  background-color: #000000;
  padding-top: 32px;
  padding-bottom: 40px;
`;

export const StyledTopRow = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${MD_AND_DOWN_QUERY} {
    flex-direction: column;
  }
`;

export const StyledBottomRow = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media ${MD_AND_DOWN_QUERY} {
    flex-direction: column;
  }
`;

export const StyledSocialMeidaRow = styled.div`
  display: flex;
  align-items: center;

  @media ${MD_AND_DOWN_QUERY} {
    margin-top: 32px;
  }
`;

export const StyledImage = styled.img`
  width: ${({ width }) => width}px;

  @media ${MD_AND_DOWN_QUERY} {
    width: ${({ mobileWidth }) => mobileWidth}px;
  }
`;

export const StyledIcon = styled.div`
  width: ${({ width }) => width}px;
  height: ${({ width }) => width}px;
  margin-right: ${({ mr }) => mr}px;
  mask-image: ${({ src }) => `url(${src})`};
  background: white;
  
  &:hover {
    background: #FF6400;
  }
`;

export const StyledFooterText = styled.div`
  font-size: 12px;
  color: #FFFFFF;
  margin-right: 24px;

  @media ${MD_AND_DOWN_QUERY} {
    margin-right: 0;
    margin-bottom: 16px;
  }
`;

export const StyledDivide = styled.div`
  width: 100%;
  height: 1px;
  background-color: #696969;
  margin: 32px 0;
`;
