/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useWeb3React } from '@web3-react/core';
import { useGetRedeemMessage } from '../../api/getRedeemMessage';
import { useRedeem } from '../../api/redeem';
import { useGetUtilityData } from '../../api/getUtilityData';
import { Logger } from '../../api/logger';
import { XS_QUERY } from '../../breakpoints';
import logoImage from '../../assets/logo.svg';
import Button from '../Button';
import { nftPropTypes, nftDefaultValue } from './propTypes';
import { StyledImage as Image } from './Styles';
import { getImageUrl } from '../../utils';
import { signTypedData } from '../../rpc';
import { useVenlyWalletContext } from '../../providers/venlyWalletProvider';

const StyledContainer = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
`;

const StyledWrapper = styled.div`
  display: flex;
  padding: 24px;

  @media ${XS_QUERY} {
    flex-direction: column;
  }
`;

const StyledImage = styled(Image)<{ image?: string }>`
  width: 50%;
  object-fit: ${(image) => !image && 'contain'};

  @media ${XS_QUERY} {
    width: 100%;
  }
`;

const StyledContent = styled.div`
  width: 100%;
  padding: 24px;
  color: white;
  max-height: 500px;
  overflow: auto;
`;

const StyledTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 12px;
`;

const StyledDescription = styled.div`
  font-size: 14px;
`;

const StyledButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledButton = styled(Button)`
  width: fit-content;
`;

const StyledError = styled.div`
  color: red;
  height: 24px;
  margin: 12px;
`;

export const StyledVideo = styled.video`
  width: 50%;
`;

const AfterRedeemedType = {
  QR_CODE: 0,
  NONE: 1,
  QR_STRING: 2,
};

const UtilityType = {
  QR_CODE: 'qrcode',
  STRING: 'string',
};

const NFTEvent = ({ nft, setQRString, setShowTicket, setEvent, campaignName, setType, redeemedDescription }: any) => {
  const { network, contractAddress, tokenId } = nft;
  const { venlyUser, venlySignTypedData, venlyWallet } = useVenlyWalletContext();
  const { provider, account, chainId: metamaskChainId } = useWeb3React();
  const userAddress = (account || '').toLowerCase();

  const { campaignUUID } = useParams<{ campaignUUID: string }>();

  const { getRedeemMessage, loadingGetRedeemMessage } = useGetRedeemMessage();
  const { redeem, loadingRedeem } = useRedeem();
  const { getUtilityData, loadingGetUtilityData } = useGetUtilityData();

  const loading = loadingGetRedeemMessage || loadingRedeem || loadingGetUtilityData;

  const chainId = metamaskChainId;

  const renderAsset = () => {
    const { video, image } = nft;
    if (video) {
      return (
        <StyledVideo autoPlay loop muted>
          <source src={video} type="video/mp4" />
        </StyledVideo>
      );
    }
    return <StyledImage alt="nft" image={image} src={getImageUrl(image) || logoImage} />;
  };

  const evmHandler = async () => {
    try {
      const value = {
        type: 'evm',
        chainId: Number(chainId),
        utilityCollectionId: campaignUUID,
        contractAddress,
        tokenId: Number(tokenId),
        address: account,
      };
      const redeemMessage = await getRedeemMessage(value as any);
      const signature = await signTypedData(provider, account, redeemMessage);
      const { utilityUri } = await redeem({
        type: 'evm',
        redeemMessage,
        signature,
      });

      if (utilityUri) {
        const { type, value } = await getUtilityData({
          utilityUri,
          address: account,
        });
        setQRString(value);
        setShowTicket(true);
        if (type === UtilityType.QR_CODE) {
          setType(AfterRedeemedType.QR_CODE);
        } else if (type === UtilityType.STRING) {
          setType(AfterRedeemedType.QR_STRING);
        }
      } else {
        setType(AfterRedeemedType.NONE);
      }

      setEvent({
        eventName: redeemedDescription || '',
        eventDescription: redeemedDescription || '',
      });
    } catch (e: any) {
      Logger.error({ error: e });
    }
  };

  const venlyHandler = async () => {
    try {
      const value = {
        type: 'evm',
        chainId: 137,
        utilityCollectionId: campaignUUID,
        contractAddress,
        tokenId: Number(tokenId),
        address: venlyWallet?.address,
      };
      const redeemMessage = await getRedeemMessage(value as any);
      const signature = await venlySignTypedData({
        walletId: venlyWallet?.id || '',
        authorization: redeemMessage,
      });
      const { utilityUri } = await redeem({
        type: 'evm',
        redeemMessage,
        signature: signature?.result.signature,
      });

      if (utilityUri) {
        const { type, value } = await getUtilityData({
          utilityUri,
          address: account,
        });
        setQRString(value);

        if (type === UtilityType.QR_CODE) {
          setType(AfterRedeemedType.QR_CODE);
        } else if (type === UtilityType.STRING) {
          setType(AfterRedeemedType.QR_STRING);
        }
      } else {
        setType(AfterRedeemedType.NONE);
      }
      setEvent({ eventName: campaignName, eventDescription: redeemedDescription });
    } catch (e) {
      console.log(e);
    }
  };

  const handleButtonClick = async () => {
    if (provider) {
      await evmHandler();
    }
    if (venlyUser) {
      venlyHandler();
    }
  };

  return (
    <StyledContainer>
      <StyledWrapper>
        {renderAsset()}
        <StyledContent>
          <StyledTitle>{nft.name || 'Title'}</StyledTitle>
          <StyledDescription>{nft.description || 'Description'}</StyledDescription>
        </StyledContent>
      </StyledWrapper>
      <StyledButtonGroup>
        <StyledButton
          onClick={async () => {
            handleButtonClick();
          }}
        >
          Redeem
        </StyledButton>
      </StyledButtonGroup>
    </StyledContainer>
  );
};

NFTEvent.propTypes = {
  nft: nftPropTypes,
  setQRString: PropTypes.func.isRequired,
  setShowTicket: PropTypes.func.isRequired,
  setEvent: PropTypes.func.isRequired,
  setType: PropTypes.func.isRequired,
  campaignName: PropTypes.string,
};

NFTEvent.defaultProps = {
  nft: nftDefaultValue,
  campaignName: undefined,
};

export default NFTEvent;
