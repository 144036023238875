import styled from 'styled-components';
import { Link } from 'react-router-dom';
import compassImage from '../../../assets/compass.svg';
import unbindingImage from '../../../assets/unbinding.svg';
import color from '../../../styles/color';
import { XS_QUERY } from '../../../breakpoints';

export const StyledHeader = styled.header`
  padding-top: 48px;
`;

export const StyledNav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 48px;
  background: ${color.purple};
  color: #b143ff;
  z-index: 5;
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 24px;
`;

export const StyledImage = styled.img`
  height: 50%;
  cursor: pointer;
`;

export const StyledUnbind = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  color: ${color.yellow};
  top: 16px;
  left: 12px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;

  &:before {
    content: '';
    background: url(${unbindingImage});
    width: 12px;
    height: 12px;
    margin-right: 6px;
  }

  div {
    color: ${color.yellowSecondary};
    max-width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media ${XS_QUERY} {
    display: none;
  }
`;

export const StyledAllNFTs = styled(Link)`
  position: fixed;
  display: flex;
  align-items: center;
  color: ${color.yellow};
  top: 16px;
  right: 12px;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;

  &:before {
    content: '';
    background: url(${compassImage});
    width: 12px;
    height: 12px;
    margin-right: 6px;
  }
`;
