import React from 'react';
import styled from 'styled-components';
import color from '../../styles/color';

const StyledButton = styled.button<ButtonStyle & { height: number }>`
  font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  padding: 3px;
  height: ${({ height }) => height}px;
  display: inline-block;
  background: ${color.purple};
  color: ${color.white};
  clip-path: ${({ height }) => `polygon(100% 0%, 100% 50%, calc(100% - ${height}px / 2) 100%, 0 100%, 0% 50%, ${height / 2}px 0)`};
  border: none;
  cursor: pointer;
  width: ${({ width }) => (width ? `${width}px` : 'unset')};
  &:hover {
    background: ${({ hoverBackgroundColor }) => hoverBackgroundColor};
    color: ${({ hoverColor }) => hoverColor};
  }
  padding: 0 24px;
`;

type ButtonStyle = {
  width?: number;
  height?: number;
  hoverColor?: string;
  hoverBackgroundColor?: string;
  isGradient?: boolean;
}

type Props = ButtonStyle & {
  className?: string;
  children: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
}

const Button = ({
  className,
  children,
  height = 36,
  hoverColor,
  hoverBackgroundColor,
  isGradient = false,
  ...props
}: Props) => (
  <StyledButton
    className={className}
    height={height}
    hoverBackgroundColor={hoverBackgroundColor}
    hoverColor={hoverColor}
    isGradient={isGradient}
    {...props}
  >
    {children}
  </StyledButton>
);

export default Button;
