import React from 'react';
import PropTypes from 'prop-types';
import PowerByRedreamerIcon from '../../../assets/power_by_redreamer.svg';
import LogoIcon from '../../../assets/logo.svg';
import FacebookIcon from '../../../assets/Facebook.svg';
import InstagramIcon from '../../../assets/Instagram.svg';
import DiscordIcon from '../../../assets/Discord.svg';
import TwitterIcon from '../../../assets/Twitter.svg';
import {
  StyledContainer,
  StyledContent,
  StyledFooterText,
  StyledIcon,
  StyledSocialMeidaRow,
  StyledTopRow,
  StyledBottomRow,
  StyledDivide,
  StyledImage,
} from './Styles';

const date = new Date();
const year = date.getFullYear();

const Footer = ({ className, color: fontColor }) => (
  <StyledContainer className={className}>
    <StyledContent>
      <StyledTopRow>
        <a rel="noreferrer" href="https://us.aorus.com/falcon-warriors/" target="_blank">
          <StyledImage src={LogoIcon} width={250} mobileWidth={200} />
        </a>
        <StyledSocialMeidaRow>
          <a rel="noreferrer" href="https://twitter.com/Falcon_Warriors" target="_blank">
            <StyledIcon src={TwitterIcon} width={32} mr={12} />
          </a>
          <a rel="noreferrer" href="https://discord.gg/NvweGCFfpe" target="_blank">
            <StyledIcon src={DiscordIcon} width={32} mr={12} />
          </a>
          <a rel="noreferrer" href="https://www.instagram.com/aorus_official/" target="_blank">
            <StyledIcon src={InstagramIcon} width={32} mr={12} />
          </a>
          <a rel="noreferrer" href="https://www.facebook.com/AorusOfficial/" target="_blank">
            <StyledIcon src={FacebookIcon} width={32} />
          </a>
        </StyledSocialMeidaRow>
      </StyledTopRow>
      <StyledDivide />
      <StyledBottomRow>
        <StyledFooterText>{`Copyright © ${year}  RE:DREAMER Lab. All rights reserved.`}</StyledFooterText>
        <a rel="noreferrer" href="https://www.redreamer.io/" target="_blank">
          <StyledImage src={PowerByRedreamerIcon} width={121} />
        </a>
      </StyledBottomRow>
    </StyledContent>
  </StyledContainer>
);

Footer.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

Footer.defaultProps = {
  className: undefined,
  color: 'white',
};

export default Footer;
