import styled from 'styled-components';
import { XS_QUERY } from '../../breakpoints';

export const StyledContainer = styled.div`
  font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
  cursor: ${({ isLoading }) => (isLoading ? 'no-allowed' : 'pointer')};
  padding: 6px;
  @media ${XS_QUERY} {
    width: 50%;
  }
`;

export const StyledImage = styled.img`
  width: 100%;
  object-fit: ${({ objectFit }) => (objectFit || 'cover')};
`;

export const StyledImageContainer = styled.div`
  width: 100%;
`;

export const StyledVideo = styled.video`
  width: 100%;
`;
