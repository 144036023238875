/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import styled from 'styled-components';
import Modal from '../../Modal';
import { connecterList, connecterConfigs } from '../../../configs/evmConnecters';
import ConnectButton from '../../ConnectButton';
import color from '../../../styles/color';
import { useVenlyWalletContext } from '../../../providers/venlyWalletProvider';

const StyledContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  filter: drop-shadow(-1px 0px 0px ${color.white}) drop-shadow(1px 1px 0px ${color.white})
    drop-shadow(0px 1px 0px ${color.white}) drop-shadow(1px -1px 0px ${color.white});
`;

const StyledTitle = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  font-size: 18px;
  color: ${color.white};
  text-align: center;
  font-weight: bold;
`;

type Props = {
  hasBackDrop: boolean;
  opened: boolean;
  onClose: () => void;
};

const BindingModal = ({ hasBackDrop, opened, onClose }: Props) => {
  const { connector, isActive } = useWeb3React();
  const { venlyUser, loading: venlyLoading, venlyActivate, venlyDeactivate } = useVenlyWalletContext();

  useEffect(() => {
    if (isActive || venlyUser) {
      onClose();
    }
  }, [isActive, venlyUser, onClose]);

  const deactivateAll = async () => {
    if (isActive && connector) {
      await connector.resetState();
      localStorage.clear();
      connector.provider = undefined;
      (connector as typeof connector & { eagerConnection: any }).eagerConnection = undefined;
    }
    if (venlyUser) {
      venlyDeactivate();
    }
  };

  return (
    <Modal
      hasBackDrop={hasBackDrop}
      opened={opened}
      onClose={onClose}
      backgroundColor={color.purple}
      closeColor={color.white}
      width="30%"
    >
      <StyledTitle>CONNECT WALLET</StyledTitle>
      <StyledContainer>
        {connecterList.map((key) => {
          const { name, active } = connecterConfigs[key];

          return (
            <ConnectButton
              key={key}
              name={name}
              onClick={async () => {
                await deactivateAll();
                await active();
              }}
            />
          );
        })}
        <ConnectButton
          name="Venly"
          activating={venlyLoading}
          connected={!!venlyUser}
          onClick={() => {
            deactivateAll();
            venlyActivate();
          }}
        />
      </StyledContainer>
    </Modal>
  );
};

BindingModal.propTypes = {
  hasBackDrop: PropTypes.bool,
  opened: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

BindingModal.defaultProps = {
  hasBackDrop: false,
  opened: false,
};

export default BindingModal;
